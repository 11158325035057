import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import numbro from 'numbro';

import * as reducers from './ducks';

import Root from './containers/Root';

numbro.registerLanguage({
  languageTag: 'pt-BR',
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => ' º',
  currency: {
    symbol: 'R$ ',
    position: 'prefix'
  },
  currencyFormat: {
    thousandSeparated: true,
    mantissa: 2
  }
});

numbro.setLanguage('pt-BR');

const rootReducer = combineReducers({
  ...reducers
});

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

export default Root;
