import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import sunburst from 'highcharts/modules/sunburst';
import moment from 'moment';
import 'moment/locale/pt';

import createClient from '../core/apollo'

import App from './App';

import 'rc-slider/assets/index.css';

sunburst(Highcharts);

moment.locale('pt');

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Carregando...',
    noData: 'Nenhum registro foi encontrado',
    contextButtonTitle: 'Exportar',
    downloadJPEG: 'Exportar como JPG',
    downloadPDF: 'Exportar como PDF',
    downloadPNG: 'Exportar como PNG',
    downloadSVG: 'Exportar como SVG',
    printChart: 'Imprimir gráfico'
  }
})

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#58a5ef',
      main: '#1689ce',
      dark: '#0d76b5',
      contrastText: '#fff'
    },
    secondary: {
      light: '#8f8f8f',
      main: '#747474',
      dark: '#515151',
      contrastText: '#fff'
    }
  }
});

function Root(props) {
  const { store } = props
  const [
    applicationClient,
    setApplicationClient
  ] = React.useState(createClient())

  const refreshApolloClient = () => {
    setApplicationClient(createClient())
  }

  return (
    <ApolloProvider client={ applicationClient }>
      <ApolloHooksProvider client={ applicationClient }>
        <Provider store={ store }>
          <MuiThemeProvider theme={ theme }>
            <ApolloConsumer>
              { client =>
                <App apollo={ client } refreshApolloClient={ refreshApolloClient }/> }
            </ApolloConsumer>
          </MuiThemeProvider>
        </Provider>
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}

export default Root
