import React, { Suspense, lazy } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import { CircularProgress } from '@material-ui/core'

import '../styles/app.css'

function App({ refreshApolloClient }) {
  const Home = lazy(() => import('../pages/Home'))
  const Admin = lazy(() => import('../pages/Admin'))
  const Login = lazy(() => import('../pages/Login'))

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      { ...rest }
      render={ props => {
        if (hasToken()) return <Component { ...props } />

        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      } }
    />
  )

  const hasToken = () => {
    return !!localStorage.getItem('TOKEN_KEY')
  }

  return (
    <Suspense fallback={
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    }>
      <Router>
        <Switch>
          <PrivateRoute path="/admin" component={ Admin } />
          <Route exact path="/" component={ Home } />
          <Route path="/login" render={ () => <Login refreshApolloClient={ refreshApolloClient } /> } />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
